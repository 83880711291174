<template>
  <v-container fluid>
    <!-- le workflow applicatif -->
    <div class="d-flex justify-center" flat tile>
      <Workflow
        width="600"
        height="180"
        :steps="workflowSteps"
        :currentStep="workflowIndex"
        :labelWidth="200"
        :lineWidth="140"
      ></Workflow>
    </div>

    <v-row justify="center">
      <v-col xl="6" lg="8" md="8" sm="10" xs="12">
        <!-- le titre et le bouton retour -->
        <div class="d-flex justify-center">
          <TitleAndReturnComponent title="Métier" />
        </div>

        <!-- la progess bar à afficher lors du chargement des données -->
        <v-progress-linear indeterminate :active="loading"></v-progress-linear>

        <!-- le détail  -->
        <DetailViewBoxComponent title="Détail du métier">
          <template v-slot:items>
            <!-- le nom -->
            <InputFieldComponent label="nom" :value="name" />

            <InputFieldComponent label="marque métier" :value="brand" />

            <InputFieldComponent
              label="nom de domaine"
              :value="domain"
              :divider="false"
            />
          </template>
        </DetailViewBoxComponent>
      </v-col>
    </v-row>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar>

  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";
import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import DetailViewBoxComponent from "@/components/ui/DetailViewBoxComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import { MetierService } from "@/service/sfr/metier_service.js";

import * as exceptions from "@/service/exception_to_message.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";


export default {
  name: "DetailCivility",

  components: {
    Workflow,
    TitleAndReturnComponent,
    DetailViewBoxComponent,
    InputFieldComponent,
  },
  mixins: [WorkflowMixin, RoleMixin, TableViewEditWorkflowMixin, SnackBarMixin,],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /** le service de chargement. */
      service: null,

      /**l'identifiant  à visualiser. */
      metierId: null,

      /**Afficher le bouton de retour */
      showBackButton: false,

      /**le nom du métier */
      name: null,

      /**la marque métier */
      brand: null,

      /**le nom de domaine */
      domain: null,
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        this.fields = [];

        let metier = await this.service.getById(this.metierId);

        this.name = metier.name;
        this.brand = metier.domainBrand.brand;
        this.domain = metier.domainBrand.domain;

        // console.log(JSON.stringify(metier, null, 4));
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {},
  mounted() {
    this.service = new MetierService(this.$api.getMetierApi());

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.metierId = this.$route.params.id;
    }

    //on affiche le bouton retour
    this.showBackButton = true;

    this.initTableViewEditWorkflow(this.canEdit, 1);

    this.load();
  },
};
</script>

<style>
</style>